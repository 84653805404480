/**
 * Allow users to preview their password
 */

$(document).on("mousedown", "[data-behavior~=password-reveal]", function() {
  var target = $("[data-type~=" + $(this).data("target") + "]");
  $(target).attr("type", "text");
});

$(document).on(
  "mouseup mouseout",
  "[data-behavior~=password-reveal]",
  function() {
    var target = $("[data-type~=" + $(this).data("target") + "]");
    $(target).attr("type", "password");
  }
);
