/**
 * Handle form using ajax and process response
 */

$(document).on("submit", "[data-behavior~=xhr-form]", function(e) {
  const form = e.target;
  $.ajax({
    type: "POST",
    url: $(form).attr("action"),
    data: new FormData(form),
    processData: false,
    contentType: false,
    dataType: "script",
  });
  return false;
});
