/**
 * Add tracking behaviour using Segment Analytics.js library.
 *
 * See https://github.com/segmentio/analytics.js
 *
 * Usage:
 * <input type="button" value="Sign Up" data-behavior="track" data-event-name="Sign Up">
 *
 * API:
 * data-track-event-name: string - Event name
 * data-track-event-type: string - Type of event. Ex: "click", "keypress"... Defaults to "click"
 * track-event-properties: JSON - Event properties
 */

import { extractTrackedProperties } from "../modules/extract-tracked-properties";

$.onmount("[data-behavior~=track]", function() {
  if (analytics == null) {
    console.warn("Analytics.js not found! User tracking is disabled!")
  }

  const event_name = $(this).data("track-event-name");
  const event_type = $(this).data("track-event-type") || "click";
  const event_properties = extractTrackedProperties($(this).data());

  if (event_name == null) {
    throw new Error("Missing property data-track-event-name!")
  }

  $(this).on(event_type, () => {
    analytics.track(event_name, event_properties);
  })
});
