/**
 * Calculations for an investment planner
 */

var moment = require("moment");

const REPAYMENTINTERVAL = 6;
const MATURITY = 48;
const YEARS = MATURITY / 12;
const NOTESIZE = 50;

export class InvestmentPlanner {
  constructor(start, topup, interest) {
    this.start = start;
    this.topup = topup;
    this.interest = (interest / 100) * 0.5;

    this._calculate();
  }

  _calculate() {
    if (!this.projections) {
      this.projections = [];

      var walletBalance = 0.0;
      var totalPortfolio = this.start;
      var schedule = [];
      var reinvestment = 0.0;
      var month = 0;

      schedule[REPAYMENTINTERVAL] = {
        notional: this.start,
        interest: this.start * this.interest,
      };

      this.projections[0] = {
        date: moment().format("MMM YYYY"),
        value: this.start,
      };

      for (month = 1; month <= MATURITY; month++) {
        if (schedule[month]) {
          walletBalance += schedule[month].notional;
          walletBalance += schedule[month].interest;
          totalPortfolio += schedule[month].interest;
        }

        reinvestment = Math.floor(walletBalance / NOTESIZE) * NOTESIZE;
        walletBalance -= reinvestment;
        totalPortfolio += this.topup;

        this.projections[month] = {
          value: totalPortfolio,
          date: moment()
            .add(month, "month")
            .format("MMM YYYY"),
        };

        schedule[month + REPAYMENTINTERVAL] = {
          notional: reinvestment + this.topup,
          interest: (reinvestment + this.topup) * this.interest,
        };
      }
    }
  }

  /**
   * Calculates and returns the portfolio by month for the investment planner
   * @return {array} the outstanding portfolio by month
   */
  monthlyDevelopment() {
    return this.projections;
  }

  yearlyDevelopment() {
    var yearlyDevelopment = [];
    var year;

    for (year = 0; year <= YEARS; year++) {
      yearlyDevelopment.push(this.projections[year * 12]);
    }
    return yearlyDevelopment;
  }

  /**
   * Calculates and returns the portfolio after some number of years
   * @param  {int} numberOfYears number of years to be used in calculation
   * @return {float}               The value after numberOfYears.
   */
  portfolioAfterYears(numberOfYears) {
    return this.projections[numberOfYears * 12].value;
  }
}
