/**
 * Methods for dealing with currency and display
 */

import { currentLocale } from "./locale";
var currencyFormatter = require("currency-formatter");

export function formatCurrency(amount, currency) {
  return currencyFormatter.format(amount, {
    code: currency,
    locale: currentLocale(),
    format: "%s%v",
  });
}
