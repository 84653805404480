/**
 * Add clipboard management using clipboardjs to all data-behavior clipboard
 * elements.
 *
 * See https://clipboardjs.com/
 *
 * Add the text that will be shared as data-clipboard-text
 */

var ClipboardJS = require("clipboard");

$.onmount("[data-behavior~=clipboard]", function() {
  new ClipboardJS("[data-behavior~=clipboard]"); // eslint-disable-line
});
