/**
 * Call select2 on all data-behavior = autocomplete-select elements
 */

require("select2");

$.onmount("[data-behavior~=autocomplete-select]", function() {
  $(this).select2({
    theme: "bootstrap4",
    width: "100%",
    closeOnSelect: false,
    scrollAfterSelect: false,
    placeholder: $(this).data("placeholder"),
  });
});
