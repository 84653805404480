/**
 * Methods for dealing with branding colors in js
 */

var colors = function(amount = 4) {
  var colorArray = ["#00919b", "#ff5a5f", "#0a3734"];

  var i;
  var output = [];
  for (i = 0; i < amount; i++) {
    output.push(colorArray[i % colorArray.length]);
  }

  return output;
};

var color = function(number = 0) {
  return colors(number + 1).pop();
};

module.exports = {
  colors,
  color,
};
