/**
 * Hide target modal on click or form submission. Target by id.
 * @example
 * <button data-behavior="close-modal" data-target="modal-id">Close</button>
 * <form data-behavior="close-modal" data-target="modal-id">...</form>
 * @example
 * <button data-behavior="close-modal" data-target="modal-id" data-delay="1000">Close</button>
 * <form data-behavior="close-modal" data-target="modal-id" data-delay="1000">...</form>
 */

$("[data-behavior~=close-modal]").each(function() {
  var target = $(this).data("target");
  var delay = $(this).data("delay");

  if ($(this).is("form")) {
    $(document).on('ajax:success', `#${target}`, function() {
      closeModal(target, delay);
    });
  } else {
    $(this).on("click", function() {
      closeModal(target, delay);
    });
  }
});

function closeModal(target, delay) {
  if (target) {
    if (delay) {
      setTimeout(function() {
        $(`#${target}`).modal("hide");
      }, delay);
    } else {
      $(`#${target}`).modal("hide");
    }
  }
}