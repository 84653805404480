/**
 * Handling radio buttons inside panels
 */

$(document).on("click", "[data-behavior~=child-radio-select]", function() {
  var type = $(this).data("type");
  var panels = $("[data-type~=" + type + "]");
  $(panels).removeClass("selected");
  $(this).addClass("selected");

  var target = $(this).data("target");
  var radios = $("[data-type~=" + target + "]");
  $(radios).prop("checked", false);
  $(this)
    .find("[data-type~=" + target + "]")
    .prop("checked", true);
});
