/**
 * Create an interest rate slider on all instances of interest-rate-slider
 * Will also bind the input to a (hidden) input element if specified
 */

var noUiSlider = require("nouislider");

$.onmount("[data-behavior~=interest-rate-slider]", function() {
  var interestRateSlider = $(this)[0];
  var boundInput = $("[data-type~=" + $(this).data("input-target") + "]");
  var interestMin = parseFloat($(interestRateSlider).data("interest-min")) || 0;

  interestRateSlider = noUiSlider.create(interestRateSlider, {
    start: [interestMin],
    step: 0.5,
    tooltips: [
      {
        to: function(value) {
          return value + "%";
        },
      },
    ],
    connect: [false, true],
    range: {
      min: 0,
      max: 8,
    },
  });

  if (boundInput) {
    interestRateSlider.on("update", function() {
      boundInput.val(interestRateSlider.get());
    });
  }
});
