/**
 * Utility to load featherlight and monkeypatch tabindex behaviour until https://github.com/noelboss/featherlight/issues/285 is fixed
 */

require("featherlight");

$.featherlight._callbackChain.beforeOpen = function(event) {
  // By overriding this function, I can prevent the messing up of tabindex values done by:
  // https://github.com/noelboss/featherlight/blob/master/src/featherlight.js#L559
};
$.featherlight._callbackChain.afterClose = function(event) {
  // See note above in $.featherlight._callbackChain.beforeOpen
};
