/**
 * Select amount slider, a behaviour very specific for the select amount slider
 */

var noUiSlider = require("nouislider");
var currencyFormatter = require("currency-formatter");

$.onmount("[data-behavior~=select-amount-slider]", function() {
  var selectAmountSlider = $(this)[0];
  var max = parseInt($(this).data("max"));
  var start = parseInt($(this).data("start"));
  var step = parseInt($(this).data("step")) || 50;
  var target = $("[data-type~=" + $(this).data("target") + "]");
  var currency = $(this).data("currency");
  var locale = $(this).data("locale");
  var halfway;

  if (max > 1000) {
    halfway = 1000;
  } else {
    halfway = Math.ceil(max / 2 / 50) * 50;
  }

  selectAmountSlider = noUiSlider.create(selectAmountSlider, {
    start: [start],
    range: {
      min: step,
      "50%": [halfway, step],
      max: max,
    },
    step: step,
    tooltips: [
      {
        to: function(value) {
          return currencyFormatter.format(value, {
            code: currency,
            locale: locale,
            format: "%s%v",
            precision: 0,
          });
        },
      },
    ],
    connect: [true, false],
  });

  selectAmountSlider.on("update", function(values, handle) {
    var value = Math.round(values[handle]);
    target.val(value);
  });

  target.on("change blur", function() {
    selectAmountSlider.set($(this).val());
  });

  target.on("keypress", function(e) {
    if (e.key === "Enter") {
      selectAmountSlider.set($(this).val());
      return false; // Return false so we do not lose focus of the input box
    }
  });
});
