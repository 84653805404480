var noUiSlider = require("nouislider");

$.onmount("[data-behavior~=maturity-slider]", function() {
  var maturitySlider = $(this)[0];
  var maturityMin = parseInt($(maturitySlider).data("maturity-min")) || 6;
  var maturityMax = parseInt($(maturitySlider).data("maturity-max")) || 48;
  var boundInputMin = $(
    "[data-type~=" + $(this).data("input-min-target") + "]"
  );
  var boundInputMax = $(
    "[data-type~=" + $(this).data("input-max-target") + "]"
  );

  maturitySlider = noUiSlider.create(maturitySlider, {
    start: [maturityMin, maturityMax],
    step: 6,
    tooltips: [
      {
        to: function(value) {
          return Math.round(value);
        },
      },
      {
        to: function(value) {
          return Math.round(value);
        },
      },
    ],
    connect: [false, true, false],
    range: {
      min: 6,
      max: 48,
    },
  });

  if (boundInputMin) {
    maturitySlider.on("update", function() {
      boundInputMin.val(maturitySlider.get()[0]);
    });
  }
  if (boundInputMax) {
    maturitySlider.on("update", function() {
      boundInputMax.val(maturitySlider.get()[1]);
    });
  }
});
