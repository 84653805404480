/**
 * Hide target on a click
 */

$(document).on("click", "[data-behavior~=hide-target]", function() {
  var target = $("[data-type~= " + $(this).data("hide-target") + "]");
  var animation = $(this).data("hide-animation");

  if (animation == "fade") {
    $(target).fadeOut();
  } else {
    $(target).slideUp();
  }
});
