/**
 * Add intro functionality using shepherd.js
 *
 * https://shipshapecode.github.io/shepherd/
 */

import Shepherd from "shepherd.js";
import "../modules/scrollTo";

$(document).on("click", "[data-behavior~=tutorial]", function() {
  var steps = $(this).data("steps");
  var tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      scrollTo: true,
      showCancelLink: true,
      scrollToHandler: function(element) {
        if (element) {
          $(element).scrollTo();
        }
      },
    },
  });

  $.each(steps, function(index, step) {
    var attachTo;
    var buttons;
    var showCancelLink;

    var prevButton = {
      text: "←",
      action: tour.back,
      classes: "bg-light border-light text-dark",
    };
    var nextButton = {
      text: "→",
      action: tour.next,
      classes: "bg-light border-light text-dark",
    };
    var finishButton = {
      text: "✓",
      action: tour.next,
      classes: "bg-light border-light text-dark",
    };

    if (step.position) {
      attachTo = {
        element: step.element,
        on: step.position,
      };
    } else {
      attachTo = step.element;
    }
    if (index === 0) {
      buttons = [nextButton];
      showCancelLink = true;
    } else if (index === steps.length - 1) {
      buttons = [prevButton, finishButton];
      showCancelLink = false;
    } else {
      buttons = [prevButton, nextButton];
      showCancelLink = true;
    }

    tour.addStep({
      id: "step" + index,
      title: step.title,
      text: step.text,
      attachTo: attachTo,
      buttons: buttons,
      cancelIcon: {
        enabled: showCancelLink,
      },
    });
  });

  tour.start();
});
