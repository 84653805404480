require("../utilities/contains-insensitive");

var previousQuery = "";
var debounce = require("lodash/debounce");

function search(query) {
  if (previousQuery !== query) {
    var searchables = $("[data-type~=searcheable]");
    var found = $(
      "[data-type~=searcheable]:containsInsensitive(" + query + ")"
    );

    searchables.hide();
    found.slideDown();
    previousQuery = query;
  }
}
var debouncedSearch = debounce(search, 300);

$(document).on(
  "keydown blur change update keyup",
  "[data-behavior~=searchbox]",
  function() {
    var query = $(this).val();
    debouncedSearch(query);
  }
);

$.onmount("[data-behavior~=searchbox]", function() {
  var query = $(this).val();
  debouncedSearch(query);
});
