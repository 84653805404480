require("../modules/featherlight");

$.onmount("[data-behavior~=select-amount]", function() {
  var target = $(this).data("target");
  var selectAmountLightbox = $("[data-type~=" + target + "]");

  $(this).featherlight(selectAmountLightbox, {
    persist: true,
    openSpeed: 150,
    closeSpeed: 150,
  });
});
