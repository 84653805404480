/**
 * Filter Projects based on interest, maturity, country and impact
 */

var projectFilterbox = require("../modules/projects-filterbox");

$(document).on(
  "click update blur change",
  "[data-behavior~=filter-projects]",
  function() {
    projectFilterbox.filterProjects();
  }
);
