/**
 * Toggle the targets visibility
 */

$(document).on("change", "[data-toggle-element]", function() {
  var target = $(this).data("toggle-element");
  $('.' + target).slideToggle();
  if (this.checked) {
    $('.' + target).removeClass('hidden');
  } else {
    $('.' + target).addClass('hidden');
  }
});
