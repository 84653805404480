/**
 * Completely remove target from dom
 */

$(document).on("click", "[data-behavior~=remove-target]", function() {
  var target = $("[data-type~= " + $(this).data("remove-target") + "]");

  $(target).slideUp();
  setTimeout(function() {
    $(target).remove();
  }, 400);
});
