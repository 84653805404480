/**
 * Define a popover on an element
 *
 * Declare data-behavior="popover" on an element and then define content and/or
 * a title using data-content or data-title.
 *
 * Other optional options are:
 *
 * data-popover-trigger: "hover" (default), "manual", "click"
 * data-popover-placement: "auto" (default), bottom, top, left, right
 * data-popover-delay: 0 (default), time in ms
 * data-popover-auto: false (default), true.
 */

function showPopover(element) {
  $(element).popover("show");
}

$.onmount("[data-behavior~=popover]", function() {
  $(this).on("shown.bs.popover", () => {
    // Make sure links work inside a popover
    $(this)
      .children(".popover")
      // Find all links inside the popover
      .find("a")
      .each(function() {
        // Make sure they open in a new tab
        $(this).attr("target", "_blank");
        // Make sure they don't close the popover
        $(this).on("click", function(e) {
          e.stopPropagation();
        });
      });
  });
  $(this).on("click", function(e) {
    // Make sure that the popover doesn't trigger route navigation when placed inside an <a> tag
    e.preventDefault();
    e.stopPropagation();
    // Enable toggling on click so on mobile a second tap removes the popover)
    if ($(this).find(".popover").length === 1) {
      $(this).popover("hide");
    } else {
      // It does nothing if the popover wasn't initialized (on first click), but without this the popover won't show on second click
      $(this).popover("show");
    }
  });
  var trigger = $(this).data("popover-trigger") || "hover";
  var content = $(this).data("popover-content") || null;
  var title = $(this).data("popover-title") || null;
  var placement = $(this).data("popover-placement") || "auto";
  var delay = $(this).data("popover-delay") || 200;
  var auto = $(this).data("popover-auto") || false;
  var html = $(this).data("popover-html") || false;

  var settings = {
    trigger: trigger,
    placement: placement,
    delay: delay,
    container: $(this),
    html,
  };

  if (content) {
    settings.content = content;
  }

  if (title) {
    settings.title = title;
  }

  $(this).popover(settings);

  if (auto) {
    window.setTimeout(showPopover, delay, $(this));
  }
});
