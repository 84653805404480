/**
 * Add a carousel using slick-carousel
 */

require("slick-carousel");

$.onmount("[data-behavior~=carousel]", function() {
  var slides = $(this).data("slides");
  var mobileSlides = $(this).data("mobile-slides");

  $(this).slick({
    slidesToShow: slides,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: mobileSlides,
        },
      },
    ],
  });
});
