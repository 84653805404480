/**
 * Method for dealing with the difference between bootstrap and rails
 * error levels
 */

var level = function(name = "info") {
  switch (name) {
    case "danger":
    case "error":
      return "danger";

    case "warning":
      return "warning";

    case "success":
      return "success";

    case "notice":
    case "info":
    default:
      return "info";
  }
};

module.exports = {
  level,
};
