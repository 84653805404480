import { Chart, DoughnutController, ArcElement } from "chart.js";
import { colors } from "../helpers/colors";

$.onmount("[data-behavior~=donutchart]", function() {
  Chart.register(DoughnutController, ArcElement);
  var labels = $(this).data("labels");
  var values = $(this).data("values");

  var data = {
    datasets: [
      {
        data: values,
        backgroundColor: colors(labels.length),
        borderColor: "#ffffff",
        borderWidth: 1,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: labels,
  };

  var options = {
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    aspectRatio: 1,
  };

  var chart = new Chart($(this), {
    type: "doughnut",
    data: data,
    options: options,
  });

  $(this).data("chart", chart);
});
