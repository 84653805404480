import "../modules/stacked-bar-chart";
import { debounce } from "lodash";
import { formatCurrency } from "../helpers/currency";
import { InvestmentPlanner } from "../modules/investment-planner";

function _showReturns() {
  var start = parseFloat($("[data-type~=investment-start-amount]").val());
  var topup = parseFloat($("[data-type~=investment-monthly-topup]").val());
  var interest = parseFloat(
    $("[data-type~=investment-average-interest]").val()
  );

  let investmentPlanner = new InvestmentPlanner(start, topup, interest);

  $("[data-type~=investment-planner-year-result]").each(function() {
    var years = $(this).data("years");
    $(this).html(
      formatCurrency(investmentPlanner.portfolioAfterYears(years), "EUR")
    );
  });

  var names = {
    value: $("[data-type~=investment-planner-chart]").data("date"),
  };

  $("[data-type~=investment-planner-chart]")
    .data("sets", investmentPlanner.yearlyDevelopment())
    .data("keys", ["value"])
    .data("label", "date")
    .data("label", "date")
    .data("names", names)
    .renderStackedBarChart();
}

var showReturns = debounce(_showReturns, 500);

$(document).on(
  "blur change",
  "[data-behavior~=investment-planner]",
  function() {
    showReturns();
  }
);

$.onmount("[data-behavior~=investment-planner]", function() {
  showReturns();
});
