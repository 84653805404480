$(document).on("change", "[data-behavior~=toggle-warning-on-select]", function(event) {
    // We split the string of toggle values into an array
    let toggleValueData = String($(this).data('toggle-value'));
    let toggleValues;
    if (toggleValueData.includes(',')) {
        toggleValues = toggleValueData.split(',');
    } else {
        toggleValues = [toggleValueData];
    }
    let targetElement = $(this).data('target-element');

    // Check if the current value of the select box is included in the toggleValues array
    if (toggleValues.includes($(this).val())) {
        $('#' + targetElement).removeClass('hidden');
    } else {
        $('#' + targetElement).addClass('hidden');
    }
});
