function numberAnimation(elem) {
  $(elem)
    .prop("Counter", 0)
    .animate(
      {
        Counter: $(elem).data("number"),
      },
      {
        duration: 2400,
        easing: "swing",
        step: function(now) {
          $(elem).text(Math.ceil(now));
        },
      }
    );
}

$.onmount("[data-behavior~=number-animation]", function() {
  numberAnimation($(this));
});
