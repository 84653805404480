/**
 * Mobile menu behaviors
 */

$(document).on("click", "[data-behavior~=mobile-menu-close]", function() {
  $("[data-type*=mobile-menu]").removeClass("open");
});

$(document).on("click", "[data-behavior~=mobile-menu-open]", function() {
  $("[data-type*=mobile-menu]").removeClass("open");

  $("[data-type~=" + $(this).data("target") + "]").addClass("open");
  $("[data-type~=mobile-menu-overlay]").addClass("open");
});
