/**
 * @fileoverview Adds extra classes to sticking elements to apply styles.
 * @example
 * <div class="my-el" data-behavior="sticky" data-offset="100" data-z-index="10">
 * Then you can add styles like:
 * .my-el.sticking {
 *  box-shadow: 0 4px 10px -6px rgba(0, 0, 0, 0.2);
 * }
 */

$("[data-behavior~=sticky]").each(function() {
  const $this = $(this);
  const el = $this[0];
  // If you need responsive offset, you can set in css
  const offset = $this.data("offset") || 0;
  const zIndex = $this.data("zIndex") || 1031;
  $(el).css({
    position: "sticky",
    top: `${offset}px`,
    zIndex,
  });
  const placeholderEl = document.createElement("div");
  const placeholder = $(placeholderEl).css({
    position: "absolute",
    top: "-1px", // Triggers the observer
    width: "100%",
    height: `${offset + 1}px`,
    transform: `translateY(-100%)`,
    pointerEvents: "none",
  });
  $(el).prepend(placeholder);
  const observer = new IntersectionObserver(
    function(entries) {
      if (entries[0].intersectionRatio < 1) {
        $(el).addClass("sticking");
      } else if (entries[0].intersectionRatio === 1) {
        $(el).removeClass("sticking");
      }
    },
    { threshold: [0, 1] }
  );
  observer.observe(placeholder[0]);
});
