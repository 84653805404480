/**
 * Submit parent form when input is changed
 */

$(document).on(
  "update blur change",
  "[data-behavior~=submit-on-change]",
  function() {
    $(this)
      .parents("form")
      .submit();
  }
);
