$(document).on('change', '[data-update-projects-count="true"]', function(event) {
    var minInterestRate = $('#minimum_interest_rate').val();
    var minCreditScore = $('#minimum_credit_score').val();
    var maxMaturity = $('#maximum_maturity_in_months').val();
    var currency = $('#currency').val();
    var investmentType = $('#investment_type').val();
    var maximumProjectAmount = $('#maximum_project_amount').val();
    var investeeMaxAmount = $('#investee_maximum_amount').val();
    var maximumAmountPerMonth = $('#maximum_amount_per_month').val();

    $.ajax({
        url: '/en-EU/account/filter_projects_amount.json',
        type: 'POST',
        dataType: 'json',
        data: {
            minimum_interest_rate: minInterestRate,
            minimum_credit_score: minCreditScore,
            maximum_maturity_in_months: maxMaturity,
            currency: currency,
            investment_type: investmentType,
            maximum_project_amount: maximumProjectAmount,
            investee_maximum_amount: investeeMaxAmount,
            maximum_amount_per_month: maximumAmountPerMonth
        },
        success: function(response) {
            $('.matching_projects_count').text(response['projects_amount']);
            $('.achievable_investment_amount').text(response['achievable_investments_amount']);
            if (response['achievable_investments_amount'] == 0) {
                $('.achievable_investment_amount_box').addClass('hidden');
            } else {
                $('.achievable_investment_amount_box').removeClass('hidden');
            }
        },
        error: function() {
            $('.matching_projects_count').text('?');
            $('.achievable_investment_amount_box').addClass('hidden');
        }
    });
});