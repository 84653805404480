import { Chart, BarController, BarElement, CategoryScale, LinearScale } from "chart.js";
import { color } from "../helpers/colors";

$.fn.renderStackedBarChart = function() {
  Chart.register(CategoryScale, LinearScale, BarController, BarElement)
  // Input as we want the api to look like
  var sets = $(this).data("sets");
  var keys = $(this).data("keys");
  var names = $(this).data("names");
  var label = $(this).data("label");
  var datasets = [];
  var labels = [];

  // Ensure we destroy the previous chart first
  var chart = $(this).data("chart");
  if (chart) {
    chart.destroy();
  }

  // Transformation to chartjs relevant objects
  keys.forEach(function(key, index) {
    var dataset = [];

    sets.forEach(function(set) {
      dataset.push(set[key]);
    });

    datasets.push({
      data: dataset,
      label: names[key],
      backgroundColor: color(index),
      borderColor: color(index),
    });
  });

  labels = sets.map(function(set) {
    return set[label];
  });

  // Data object as required by chartjs
  var data = {
    datasets: datasets,
    labels: labels,
  };

  //Options object as required by chartjs
  var options = {
    scales: {
      x: {
        stacked: true, 
      },
      y: {
        stacked: true, 
      },
    },
    layout: {
      padding: 0,
    },
  };

  chart = new Chart($(this), { type: "bar", data: data, options: options });
  $(this).data("chart", chart);
};
