initConsentManager();

/**
 * Initialise Segment's Consent Manager.
 * @returns {void}
 * @see https://github.com/segmentio/consent-manager
 */
function initConsentManager() {
  // __CONSENT_MANAGER__ is initialized in _analytics_body.html.erb
  const consentConfig = window.__CONSENT_MANAGER__ || {};
  const writeKey = consentConfig.key;
  const locale = consentConfig.locale || "en-EU";
  const {
    bannerContentText,
    bannerSubContent,
    preferencesDialogTitle,
    preferencesDialogContent,
    cancelDialogTitle,
    cancelDialogContent,
    acceptAll: acceptAllText,
    denyAll: denyAllText,
    changePreferences: changePreferencesText,
    privacyStatement,
    preferencesDialogTemplate,
  } = consentConfig.translations || {};
  if (!writeKey) {
    console.error("No Segment write key for consent manager found.");
    return;
  }
  window.consentManagerConfig = function(exports) {
    var React = exports.React;
    var inEU = exports.inEU;

    var bannerContent = React.createElement(
      "span",
      { className: "cookie-consent__banner-message" },
      bannerContentText,
      " ",
      React.createElement(
        "a",
        {
          href: `/${locale}/pages/privacy-statement`,
          target: "_blank",
        },
        privacyStatement
      ),
      "."
    );

    const bannerActionsBlock = ({ acceptAll, denyAll, changePreferences }) => {
      return React.createElement(
        "div",
        { className: "cookie-consent__banner-actions" },
        [
          React.createElement(
            "button",
            {
              className:
                "cookie-consent__button cookie-consent__button__secondary",
              onClick: () => changePreferences(),
            },
            changePreferencesText
          ),
          React.createElement(
            "button",
            {
              className:
                "cookie-consent__button cookie-consent__button__secondary",
              onClick: () => denyAll(),
            },
            denyAllText
          ),
          React.createElement(
            "button",
            {
              className:
                "cookie-consent__button cookie-consent__button__primary",
              onClick: () => acceptAll(),
            },
            acceptAllText
          ),
        ]
      );
    };

    return {
      container: "#cookie-consent-container",
      writeKey: writeKey,
      shouldRequireConsent: inEU,
      bannerContent,
      bannerSubContent,
      preferencesDialogTitle,
      preferencesDialogContent: preferencesDialogContent,
      cancelDialogTitle: cancelDialogTitle,
      cancelDialogContent: cancelDialogContent,
      closeBehavior: "accept",
      bannerActionsBlock,
      preferencesDialogTemplate,
    };
  };
}

initConsentManager();

$.onmount("[data-behavior~=consent-activator]", function() {
  $(this).on("click", function() {
    if (window.consentManager) window.consentManager.openConsentManager();
  });
});
