/**
 * Match height for all elements with equal data-mh property
 */

require("jquery-match-height");

$.onmount("body", function() {
  var groups = $.unique(
    $("[data-mh]")
      .map(function() {
        return $(this).data("mh");
      })
      .toArray()
  );

  groups.forEach(function(group) {
    $("[data-mh~=" + group + "]").matchHeight();
  });

  $.fn.matchHeight._update();
});
