/**
 * Use bootstrap-notify to render animated hovering notifications
 */

import { level } from "../helpers/levels";

$.flash = function(railsLevel, message) {
  $.notify(
    {
      message: message,
      target: "_self",
    },
    {
      type: level(railsLevel),
      delay: 15000,
      offset: {
        y: 60,
        x: 0,
      },
      z_index: 1060,
      placement: {
        from: "top",
        align: "center",
      },
      animate: {
        enter: "animated fadeInDown",
        exit: "animated fadeOutDown",
      },
    }
  );
};

$.onmount("[data-behavior~=notification]", function() {
  var railsLevel = $(this).data("level");
  var message = $(this).data("message");
  $.flash(railsLevel, message);
});
