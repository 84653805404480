var map = require("lodash/map");
var includes = require("lodash/includes");
require("nouislider");

module.exports = {
  filterProjects: function() {
    // Set variables
    var fadeDelay = 320;

    var projects = $("[data-type~=filterable-project]");

    // Get all relevant values
    var interest = $(
      "[data-behavior~=interest-rate-slider]"
    )[0].noUiSlider.get();

    var maturityMin = $(
      "[data-behavior~=maturity-slider]"
    )[0].noUiSlider.get()[0];

    var maturityMax = $(
      "[data-behavior~=maturity-slider]"
    )[0].noUiSlider.get()[1];

    var impactSelected = map($("[data-type~=impact-select]:checked"), "name");
    var countrySelected = map($("[data-type~=country-select]:checked"), "name");

    // Use variable to see if all projects are hidden without domdiving or timers
    var allHidden = true;

    // Loop through all projects and show/hide them
    $.each(projects, function(index, project) {
      var hide = false;

      // Remove project based on interest
      var projectInterest = parseFloat($(this).attr("data-interest"));
      if (projectInterest < interest) {
        hide = true;
      }

      // Remove project based on maturity
      var projectMaturity = parseFloat($(this).attr("data-maturity"));
      if (projectMaturity < maturityMin || projectMaturity > maturityMax) {
        hide = true;
      }

      // Remove project based on countries
      var projectCountry = $(this).attr("data-country");
      if (countrySelected.length > 0) {
        if (!includes(countrySelected, projectCountry)) {
          hide = true;
        }
      }

      // Remove project based on impact
      // Get all project impact categories as an array
      var projectImpactCategories = $(this)
        .attr("data-impact")
        .split(" ");

      if (impactSelected.length > 0) {
        // If we have impact types selected we start by assuming this project
        // should be hidden
        var impactHide = true;

        // If there is a single impact category from this project in the list of
        // selected impact types, we do NOT hide this project.
        $.each(projectImpactCategories, function(index, projectImpact) {
          if (includes(impactSelected, projectImpact)) {
            impactHide = false;
          }
        });
        if (impactHide) {
          hide = true;
        }
      }

      // Show/Hide the project
      if (hide) {
        $(project).fadeOut(fadeDelay);
      } else {
        $(project).fadeIn(fadeDelay);
        allHidden = false;
      }
    });

    // Show no-projects element if there are no more projects
    if (allHidden) {
      $("[data-type~=show-no-projects]").fadeIn(fadeDelay);
    } else {
      $("[data-type~=show-no-projects]").fadeOut(fadeDelay);
    }
  },
};
