/**
 * Handling frontend part of payment method selection
 */

$(document).on(
  "click",
  "[data-behavior~=payment-method-selection]",
  function() {
    // Handle styling
    var paymentMethods = $("[data-type~=payment-method]");
    $(paymentMethods).removeClass("selected");
    $(this).addClass("selected");

    // Handle radio
    var paymentRadios = $("[data-type~=payment-method-radio]");
    $(paymentRadios).prop("checked", false);
    $(this)
      .find("[data-type~=payment-method-radio]")
      .prop("checked", true);

    // Update transaction costs and total amount
    $("[data-type~=amount-due]").html($(this).data("total-amount"));
    $("[data-type~=applied-payment-fee]").html($(this).data("fee-amount"));

    // Show button
    $("[data-type~=payment-button]").fadeIn();
  }
);
