/**
 * Behavior that handles a tab bar with tabs
 */

$.onmount("[data-behavior~=tabs]", function() {
  var tabCollection = $("[data-type~=" + $(this).data("target-tabs") + "]");
  var tabBar = $(this);

  // Hide all tabs in collection
  $(tabCollection).hide();

  // Show the active tab
  $(tabCollection)
    .filter($("[data-type~=active]"))
    .show();

  $(tabBar)
    .find($("[data-type~=tab-toggle]"))
    .on("click", function() {
      // Set active class on correct toggle
      $(tabBar)
        .find("[data-type~=tab-toggle]")
        .removeClass("active");
      $(this).addClass("active");

      $(tabCollection).hide();
      $(tabCollection)
        .filter($("[data-type~=" + $(this).data("target-tab") + "]"))
        .show();
    });
});
