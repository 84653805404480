/**
 * Countdown in this element until 0
 */

var moment = require("moment");
var humanizeDuration = require("humanize-duration");

jQuery.fn.countDownUpdate = $.fn.countDownUpdate = function() {
  $(this).each(function() {
    var startTime = moment($(this).data("time"));
    var now = moment();
    var locale = $(this).data("locale");
    var difference = startTime.diff(now);
    var showTarget = $(
      "[data-type~=" + $(this).data("show-on-countdown-target") + "]"
    );
    var hideTarget = $(
      "[data-type~=" + $(this).data("hide-on-countdown-target") + "]"
    );

    if (difference > 0) {
      var humanized = humanizeDuration(difference, {
        units: ["h", "m", "s"],
        language: locale,
        round: true,
      });
      $(this).html(humanized);
      $(showTarget).hide();
    } else {
      $(showTarget).slideDown();
      $(hideTarget).slideUp();
    }
  });
};

function countDownUpdate() {
  $("[data-behavior~=countdown]").countDownUpdate();
}

window.setInterval(countDownUpdate, 1000);
