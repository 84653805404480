require("./maturity-slider");
require("./interest-rate-slider");
require("nouislider");

$(document).on("click", "[data-behavior~=clear-filterbox]", function() {
  // Clear select boxes
  var selectBoxes = $("[data-type~=impact-select],[data-type~=country-select]");
  selectBoxes.prop("checked", false);
  selectBoxes.blur();

  // Clear the maturity slider
  $("[data-behavior~=maturity-slider]")[0].noUiSlider.set([6, 48]);

  // Clear the interest slider
  $("[data-behavior~=interest-rate-slider]")[0].noUiSlider.set(0);

  return false;
});
