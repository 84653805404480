import { chain, keys, startsWith } from 'lodash'

export function extractTrackedProperties(dataAttrs) {
  const TRACKED_PROPERTIES_PREFIX = "trackEventProperties";

  const isTrackedProperty = (prop) => startsWith(prop, TRACKED_PROPERTIES_PREFIX);

  const removePrefix = (prop) => chain(prop)
    .slice(TRACKED_PROPERTIES_PREFIX.length)
    .join("")
    .value()

  return chain(keys(dataAttrs))
    .filter(isTrackedProperty)
    .reduce((acc, prop) => {
      acc[removePrefix(prop)] = dataAttrs[prop];
      return acc;
    }, {})
    .value()
}
