/**
 * Automatically open a lightbox on dom ready
 */

require("../modules/featherlight");

$.onmount("[data-behavior~=auto-open-lightbox]", function() {
  $.featherlight($(this), {
    openSpeed: 150,
    closeSpeed: 150,
  });
});
