/**
 * Update payment fees in payment method objects
 */

import { formatCurrency } from "../helpers/currency";

jQuery.fn.updatePaymentFees = $.fn.updatePaymentFees = function(
  amountCents,
  currency
) {
  $(this).each(function() {
    var feePercentage = $(this).data("fee-percentage");
    var feeCents = $(this).data("fee-cents");
    var feeTotal = (amountCents * feePercentage + feeCents) / 100;
    var totalAmount = amountCents / 100 + feeTotal;

    $(this).data("total-amount", formatCurrency(totalAmount, currency));
    $(this).data("fee-amount", formatCurrency(feeTotal, currency));
    $(this)
      .find("[data-type~=transaction-costs]")
      .html(formatCurrency(feeTotal, currency));
  });
};

$(document).on(
  "update change blur keyup",
  "[data-behavior~=update-payment-fees]",
  function() {
    var amountCents = $(this).val() * 100;
    var currency = $(this).data("currency");
    $("[data-type~=payment-method]").updatePaymentFees(amountCents, currency);
  }
);

$.onmount("[data-behavior~=update-payment-fees]", function() {
  $(this).blur();
});
