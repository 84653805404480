/**
 * Tooltip
 *
 * Add a tooltip that shows on hover or touch. Based on bootstraps
 * implementation of popover.js Use the following data attributes to configure
 * the tooltip:
 *
 * behaviour: tooltip
 * data-tooltip-placement: top,right,bottom,left
 * data-tooltip-title: The content of the tooltip
 * data-tooltip-html: true to render html
 */

$.onmount("[data-behavior~=tooltip]", function() {
  var content = $(this).data("tooltip-content") || null;
  var html = $(this).data("tooltip-html") || false;
  var placement = $(this).data("tooltip-placement") || "auto";

  var settings = {
    title: content,
    html: html,
    placement: placement,
    trigger: "click",
  };

  $(this).tooltip(settings);
});
