/**
 * Make elements expandable
 */

$(document).on(
  "click",
  "[data-behavior~=expandable] [data-type~=trigger]",
  function() {
    var expandable = $(this).parent("[data-behavior~=expandable]");

    var expands = $(expandable).find("[data-type~=expands]");
    var expandables = $("[data-behavior~=expandable]");
    var allExpands = $("[data-behavior~=expandable] [data-type~=expands]");

    var caret = $(expandable).find("[data-type~=caret]");
    var allCarets = $("[data-behavior~=expandable] [data-type~=caret]");

    allCarets.addClass("lahi-down-open");
    allCarets.removeClass("lahi-up-open");

    if ($(expandable).hasClass("active")) {
      $(expandable).removeClass("active");
      expands.slideUp(200);
    } else {
      expandables.removeClass("active");
      caret.addClass("lahi-up-open");
      caret.removeClass("lahi-down-open");

      allExpands.slideUp(200);
      $(expandable).addClass("active");
      expands.slideDown(200);
    }
  }
);

$.onmount("[data-behavior~=expandable] [data-type~=expands]", function() {
  $(this).hide();
});
