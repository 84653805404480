/**
 * Adds a scrollTo behaviour to jQuery elements
 */

$.fn.scrollTo = function() {
  var topoffset = 80;
  var element = $(this);

  var offset = element.offset().top;
  var height = element.height();
  var windowHeight = $(window).height();

  if (height < windowHeight - topoffset) {
    offset = offset - ((windowHeight + topoffset) / 2 - height / 2);
  } else {
    offset = offset - topoffset;
  }

  $("html, body").animate(
    {
      scrollTop: offset,
    },
    500
  );
};
