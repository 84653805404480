/**
 * Listen for noUiSlider changes and use projectFilterbox to filter projects
 * We update live on slider changes
 */

var projectFilterbox = require("../modules/projects-filterbox");

$.onmount("[data-behavior~=filter-projects-slider]", function() {
  $(this).noUiSlider.on("update", function() {
    projectFilterbox.filterProjects();
  });
});
