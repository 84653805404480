/**
 * Update target input when input is changed
 */

$(document).on(
  "click update blur change",
  "[data-behavior~=set-input-on-change]",
  function() {
    var target = $("[data-type~=" + $(this).data("target") + "]");
    $(target).val($(this).val());
  }
);
