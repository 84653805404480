/**
 * Create a slider element which sets target input element to specified value
 * using noUislider
 */

var noUiSlider = require("nouislider");

$.onmount("[data-behavior~=input-slider]", function() {
  function fixInputFromValue(value) {
    var rounded = Math.round(value / step) * step;
    return prefix + rounded + suffix;
  }

  var slider = $(this)[0];
  var boundInput = $("[data-type~=" + $(slider).data("target") + "]");
  var start = parseFloat($(slider).data("start")) || 0;
  var step = parseFloat($(slider).data("step")) || 1;
  var min = parseFloat($(slider).data("min")) || 0;
  var max = parseFloat($(slider).data("max")) || 10;

  var prefix = $(slider).data("prefix") || "";
  var suffix = $(slider).data("suffix") || "";

  noUiSlider.create(slider, {
    start: [start],
    step: step,
    tooltips: [
      {
        to: fixInputFromValue,
      },
    ],
    connect: [true, false],
    range: {
      min: min,
      max: max,
    },
  });

  if (boundInput) {
    slider.noUiSlider.on("update", function() {
      boundInput.val(slider.noUiSlider.get());
      boundInput.blur();
    });
  }
});
