/**
 * Show target on click
 */

$(document).on("click", "[data-behavior~=show-target]", function() {
  var target = $("[data-type~= " + $(this).data("show-target") + "]");
  var animation = $(this).data("show-animation");

  if (animation == "fade") {
    $(target).fadeIn();
  } else {
    $(target).slideDown();
  }
});
