/**
 * Lazyload images with data-behavior lazyload
 */

// src needs to be set with the fallback image
// eg: src="thumbnail.jpg"
//
// data-src needs to be set with the image to be lazyloaded
// eg data-src="large.jpg"
//
// data-srcset can be set with an attribute that loads images responsively
// eg: data-srcset="small.jpg 480w, medium.jpg 640w, large.jpg 1024w"
//
// also works on non-img elements and a background-image css attribute
// Documentation: https://github.com/tuupola/jquery_lazyload

require("lazyload");

$.onmount("[data-behavior~=lazyload]", function() {
  lazyload($(this).get());
});
