/**
 * Add a mask to form elements with a data-behaviour=~"input-mask"
 *
 * Specify a pattern through data-mask-pattern:
 * data-mask-pattern="99-99-9999"
 *
 * or alternatively, specify a regex pattern through data-regex-mask-pattern:
 * data-regex-mask-pattern="\\d{9}"
 *
 * See https://github.com/RobinHerbots/Inputmask
 */

import Inputmask from "inputmask";

$.onmount("[data-behavior~=input-mask]", function() {
  var regexPattern = $(this).data("regex-mask-pattern");
  var pattern = $(this).data("mask-pattern");
  var placeholder = $(this).attr("placeholder");

  if (regexPattern) {
    var mask = new Inputmask({ regex: regexPattern, placeholder: placeholder });
  } else {
    var mask = new Inputmask(pattern, { placeholder: placeholder });
  }

  mask.mask($(this));
});
